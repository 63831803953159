:root {
  --lego-yellow : rgb(255, 207, 0);
  --lego-action : rgb(253, 128, 36);
}

html:not([lang=ko]):not([lang=zh]):not([lang=ja])>body {
  font-family: Cera Pro,sans-serif;
}

body {
  width: 100%;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  color: #2c2c2c;
  background: #fff;
}

p {
  margin: 0;
}

button {
  display: block;
  width: 100%;
  padding: 0.9375rem;
  border-style: solid;
  border-radius: 4px;
  border-collapse: collapse;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5625rem;
  background-color: var(--lego-action);
  border-color: var(--lego-action);
  color: rgb(0, 0, 0);
  position: relative;
  border-width: 2px;
  cursor: pointer;
}

button:hover {
  background-color: #fff;
}

.lego-part--name, 
.lego-part--id {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.6875rem;
}

.lego-part--name {
  border-bottom: 3px solid #2c2c2c;
}

.lego-part--id {
  font-weight: 600;
}

.lego-main {
  display: grid;
  grid-template-areas:
    "webcam base"
    "button button"
    "capture capture";
  /* grid-template-rows: 3fr 1fr 1fr; */
  grid-template-columns: 1fr 1fr;
  max-width: 100rem;
  margin: 0px auto;
  padding: 10px;
}

@media screen and (max-width: 920px) {

  .lego-main {
    display: block;
  }
  
}



.lego-header {
  grid-area: header;
  height: 84px;
  background-color: var(--lego-yellow);
}

.lego-webcam {
  grid-area: webcam;
  /* background-color: #ffa08c; */

  min-width: 480px;
}

.lego-webcam video {
  width: 100%;
}

.lego-baseplate,
.lego-webcam {
  margin-bottom: 5px;
}

.lego-baseplate {
  grid-area: base;
  /* background-color: red; */
}

.lego-part {
  grid-area: part;
  /* background-color: lime; */
  padding: 20px;
}

.lego-part--image {
  max-height: 230px;
}

.lego-part--link {
  display: flex;
  justify-content: flex-end;
}

.lego-part--link img {
  width: 100px;
}

.lego-devices {
  grid-area: devices;
  /* background-color: blue; */
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

.lego-devices--camera {
  cursor: pointer;
}

.lego-devices--camera:nth-child(n+2) {
  margin-left: 5px;
}



.lego-devices--camera:hover video{
  outline: 3px dashed var(--lego-action);
}

.lego-button {
  grid-area: button;
  position: absolute;
  top: 12px;
  right: 10px;
  left: 10px;
}

.lego-capture {
  grid-area: capture;
  /* background-color: gold; */
  display: inline-flex;
  width: 100%;
  justify-content: left;
  padding-top: 10px;
}

.lego-logo {
  width: 60px;
  margin: 10px 20px;
}



.target-box-container {
  position: relative;
  overflow: hidden;
}

.target-box {
  border: 2px solid red;
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}


.target-box {
  animation-name: fadeIn;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;  
}

.target-box.fadeOut {
  animation-delay: 3s;
  animation-duration: 0.25s;
  animation-name: fadeOut;
}

@keyframes fadeIn { 0% { opacity: 0; visibility: hidden; } 100% { opacity: 1; visibility: visible; }}
@keyframes fadeOut { 0% { opacity: 1; visibility: visible; } 100% { opacity: 0; visibility: hidden;  }} 
